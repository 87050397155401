import React from 'react'

import { style, Container } from './Input.style'

const Input = ({
  className,
  style,
  label,
  type,
  contextThis,
  name,
  placeholder,
  required,
  textarea,
  parentObj = 'form',
}) => {
  const props = {
    type: type || 'text',
    name,
    required,
    placeholder,
    defaultValue: (contextThis.state[parentObj] && contextThis.state[parentObj][name]) || '',
    onBlur: (e) => {
      contextThis.setState({
        [parentObj]: {
          ...contextThis.state[parentObj],
          [name]: e.target.value,
        },
      })
    },
  }
  return (
    <Container className={className} style={style}>
      <div className="label">{label}</div>
      <div className={textarea ? 'textareaWrapper' : 'inputWrapper'}>
        {textarea ? <textarea rows={2} {...props} /> : <input {...props} />}
      </div>
    </Container>
  )
}

export default Input
