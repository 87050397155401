export default ({
  name,
  email,
  phone,
  location,
  notes,
  companyName,
  companyWebsite,
  companyEmail,
  companyPhone,
  companyTypes,
  additionalRequirements,
  chairProps,
  url,
}) => {
  return `
    <table
      class="m_4726344385676614233body"
      style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse"
    >
      <tbody>
        <tr>
          <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
            <table
              class="m_4726344385676614233header m_4726344385676614233row"
              style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px"
            >
              <tbody>
                <tr>
                  <td
                    class="m_4726344385676614233header__cell"
                    style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                  >
                    <center>
                      <table
                        class="m_4726344385676614233container"
                        style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto"
                      >
                        <tbody>
                          <tr>
                            <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
                              <table
                                class="m_4726344385676614233row"
                                style="width:100%;border-spacing:0;border-collapse:collapse"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="m_4726344385676614233shop-name__cell"
                                      style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                                    >
                                      <img
                                        src="https://elitehts.com/wp-content/uploads/2018/12/cropped-SCREEN_ETS_Logomark_K-1.png"
                                        alt="elitehts"
                                        width="100"
                                        class="CToWUd"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="m_4726344385676614233row m_4726344385676614233content"
              style="width:100%;border-spacing:0;border-collapse:collapse"
            >
              <tbody>
                <tr>
                  <td
                    class="m_4726344385676614233content__cell"
                    style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;padding-bottom:40px'
                  >
                    <center>
                      <table
                        class="m_4726344385676614233container"
                        style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto"
                      >
                        <tbody>
                          <tr>
                            <td style='width:100%; font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
                              <h2 style="font-weight:normal;font-size:20px;margin:0 0 10px"> Hello ${name}, </h2>
                              <p style="color:#777!important;line-height:150%;font-size:16px;margin:0">
                              This is an automated response confirming receipt of ${
                                url
                                  ? `<a href="${url}">your custom theater couch configuration</a>`
                                  : 'your custom theater couch configuration'
                              } and quotation request.
                                <br />
                                <br />
                                With so many possible configurations, rarely are two submissions ever the same, so we always look forward to seeing new creations from our clients every day. We're sure you've created something very unique and special as well. 
                                <br />
                                <br />
                                Our current response time is approximately 1 business day, however if you require immediate assistance, please call our wonderful project managers, Cynthia or Tara at 1-604-575-8310 (8am to 4pm PST).
                                <br />
                                <br />
                                Concierge service is one of the 4 C's of our Diamond Standard philosophy in addition to Craftsmanship, Comfort and Customization. So our goal is to make this process as easy and seamless as possible for you. For our registered dealers and designers, this includes complimentary fabric samples, catalogs, and 3d layout blueprints in addition to your quotation.
                                <br />
                                <br />
                                For more information on our Diamond certification, please click here : <a href="https://elitehts.com/about/diamond-elite-certification/">https://elitehts.com/about/diamond-elite-certification/</a>
                                <br />
                                <br />
                                <br />
                                <style>.datagrid table { border-collapse: collapse; text-align: left; width: 100%; } .datagrid {font: normal 12px/150% Arial, Helvetica, sans-serif; background: #fff; overflow: hidden; }.datagrid table td, .datagrid table th { padding: 6px 10px; }.datagrid table tbody td { color: #7D7D7D!important; font-size: 14px;border-bottom: 1px solid #E1EEF4;font-weight: normal; }.datagrid table tbody td:first-child { border-left: none; }.datagrid table tbody tr:last-child td { border-bottom: none; }</style>
                                <div class="datagrid">
                                  <table>
                                  <tbody>
                                    <tr><td><b>Name</b></td><td>${name || '---'}</td></tr>
                                    <tr><td><b>Email</b></td><td>${email || '---'}</td></tr>
                                    <tr><td><b>Phone</b></td><td>${phone || '---'}</td></tr>
                                    <tr><td><b>Location</b></td><td>${location || '---'}</td></tr>
                                    ${notes ? `<tr><td><b>Additional Notes</b></td><td> ${notes} </td></tr>` : ''}
                                    ${
                                      companyName
                                        ? `<tr><td><b>Company Name</b></td><td> ${companyName} </td></tr>`
                                        : ''
                                    }
                                    ${
                                      companyWebsite
                                        ? `<tr><td><b>Company Website</b></td><td> ${companyWebsite} </td></tr>`
                                        : ''
                                    }
                                    ${
                                      companyEmail
                                        ? `<tr><td><b>Company Email</b></td><td> ${companyEmail} </td></tr>`
                                        : ''
                                    }
                                    ${
                                      companyPhone
                                        ? `<tr><td><b>Company Phone</b></td><td> ${companyPhone} </td></tr>`
                                        : ''
                                    }
                                    ${
                                      companyTypes && companyTypes.join(', ')
                                        ? `<tr><td><b>Company Type</b></td><td>${companyTypes &&
                                            companyTypes.join(', ')}</td></tr>`
                                        : ''
                                    }
                                    ${
                                      additionalRequirements && additionalRequirements.join(', ')
                                        ? `<tr><td><b>Additional Requirements</b></td><td>${additionalRequirements &&
                                            additionalRequirements.join(', ')}</td></tr>`
                                        : ''
                                    }
                                    <tr><td><b></b></td><td></td></tr>
                                    <tr><td><b></b></td><td></td></tr>
                                    <tr><td><b>COUCH DETAILS: </b></td><td></td></tr>
                                    ${chairProps}
                                  </tbody>
                                </table>                                  
                                </div>
                                <br />
                                <br />
                                <p style="color:#777!important;line-height:150%;font-size:16px;margin:0">
                                We look forward to corresponding with you and providing you with the finest theater seating in the world.
                                <br />
                                <br />
                                Warmest Regards,
                                <br />
                                <br />
                                The Elite HTS Team
                                1-604-575-8310
                                sales@elitehts.com
                                www.elitehts.com
                                <br />
                                <tr>
                                <td
                                  style='width:50%;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                                  align="left"
                                >
                                  <a
                                    href="https://www.facebook.com/elitehts/"
                                    style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                                    target="_blank"
                                  >
                                    <img
                                      src="https://i.postimg.cc/v1dW2tVB/circle-social-facebook.png"
                                      style="height:21px;width:21px;"
                                      class="CToWUd"
                                      alt="Facebook"
                                    />
                                  </a>
                               
                                  <a
                                    href="https://www.instagram.com/elitehts/"
                                    style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                                    target="_blank"
                                  >
                                    <img
                                      src="https://i.postimg.cc/rzhNy4vn/circle-social-instagram.png"
                                      style="height:21px;width:21px;"
                                      class="CToWUd"
                                      alt="Instagram"
                                    />
                                  </a>
                               
                                  <a
                                    href="https://twitter.com/elitehts"
                                    style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                                    target="_blank"
                                  >
                                    <img
                                      src="https://i.postimg.cc/hf68TB4V/circle-social-twitter.png"
                                      style="height:21px;width:21px;"
                                      class="CToWUd"
                                      alt="Twitter"
                                    />
                                  </a>
                               
                                  <a
                                    href="https://www.linkedin.com/company/elite-home-theater-seating/"
                                    style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                                    target="_blank"
                                  >
                                    <img
                                      src="https://i.postimg.cc/9D9dBnzK/circle-social-linkedin.png"
                                      style="height:21px;width:21px;"
                                      class="CToWUd"
                                      alt="LinkedIn"
                                    />
                                  </a>
                                </td>
                              </tr>
                              </p>
                            </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `
}
