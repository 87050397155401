const hideAllWidthNodes = () => {
  const nodesToHide = ['table_oval', 'table_rectangular']
  const promisses = []
  nodesToHide.forEach((node) => {
    promisses.push(window.api.node_hide(node))
  })
  return Promise.all(promisses)
}

const showTable = (nodeName) => {
  return hideAllWidthNodes().then(() => {
    return nodeName && window.api.node_show(nodeName)
  })
}

export { showTable, hideAllWidthNodes }
