import { srgbToLinear, hexToRgb } from './colorTools.helper'
const changeColor = (overwriteChannels, hex, _api, materialName) => {
  const api = _api || window.api
  return new Promise((res) => {
    if (!overwriteChannels) return res()
    const materials = [materialName]
    return materials.forEach((material) => {
      const defaultMaterial = { ...api._getMaterialByName(material) }
      const linearColor = srgbToLinear(hexToRgb(hex))
      defaultMaterial.channels = overwriteChannels(defaultMaterial.channels, linearColor)

      return api.setMaterial(defaultMaterial)
    })
  })
}

export { changeColor }
