import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px 0 15px 0;
  .label {
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    margin: 5px 0;
  }
  .inputWrapper,
  .textareaWrapper {
    isolation: isolate;
    box-sizing: border-box;
    max-width: 100%;
    font-weight: 100;
    position: relative;
    vertical-align: middle;
    input,
    textarea {
      z-index: 100;
      height: 100%;
      width: 100% !important;
      padding: 10px 15px;
      box-sizing: border-box;
      background: none;
      border: 0;
      color: #333;
      box-shadow: inset 0 0 0 2px #d6d6d6;
      -webkit-appearance: none;
      font-size: 16px;
      font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      &:focus {
        outline: none;
      }
    }
    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 1;
    }

    & {
      transition: color 0.15s;
    }
    &::before,
    &::after {
      border: 2px solid transparent;
      width: 0;
      height: 0;
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
    &:focus-within,
    &:hover {
      color: #333;
    }
    &:focus-within::before,
    &:hover::before,
    &:focus-within::after,
    &:hover::after {
      width: 100%;
      height: 100%;
    }
    &:focus-within::before,
    &:hover::before {
      border-top-color: #b99777;
      border-right-color: #b99777;
      transition: border-color 0.2s ease-in-out, width 0.15s ease-in-out, height 0.15s ease-in-out 0.15s;
    }
    &:focus-within::after,
    &:hover::after {
      border-bottom-color: #b99777;
      border-left-color: #b99777;
      transition: border-color 0.2s ease-in-out 0.3s, width 0.15s ease-in-out 0.3s, height 0.15s ease-in-out 0.45s;
    }
  }
  .inputWrapper {
    height: 45px;
  }
  .textareaWrapper {
    min-height: 45px;

    textarea {
      margin: -1.5px 0;
    }
  }
`

export const style = {}

export default style
