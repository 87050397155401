import React from 'react'
// import 'inobounce'

import Configurator from 'containers/Configurator'
import Header from 'components/Header'
import { chaiseWidths, leathers, cupholders, tables } from 'mappers'

import './App.css'

function parseQueryToConfigs() {
  const parameters = new URLSearchParams(window.location.search)
  const configs = {}
  if (parameters.has('export')) {
    configs['forExport'] = parameters.get('export')
  }
  if (parameters.has('home_theater_seat_width')) {
    configs['chaiseWidth'] = chaiseWidths.filter((el) => el.name === parameters.get('home_theater_seat_width'))[0]
  }
  if (parameters.has('leather_1_material') && parameters.has('leather_1_color')) {
    configs['leather1'] = leathers
      .flat()
      .filter(
        (el) =>
          el.leatherName === parameters.get('leather_1_material') && el.colorName === parameters.get('leather_1_color')
      )[0]
  }
  if (parameters.has('leather_2_material') && parameters.has('leather_2_color')) {
    configs['leather2'] = leathers
      .flat()
      .filter(
        (el) =>
          el.leatherName === parameters.get('leather_2_material') && el.colorName === parameters.get('leather_2_color')
      )[0]
  }
  if (parameters.has('home_theater_chair_cupholder')) {
    configs['cupholder'] = cupholders.filter((el) => el.name === parameters.get('home_theater_chair_cupholder'))[0]
  }
  if (parameters.has('swivel_tray')) {
    configs['table'] =
      parameters.get('swivel_tray') === 'no' ? 'no' : tables.filter((el) => el.name === parameters.get('swivel_tray'))[0]
  }
  return configs
}

function App() {
  return (
    <div
      // style={{
      //   height: window.innerHeight,
      //   WebkitOverflowScrolling: 'touch',
      //   overflowY: 'scroll',
      // }}
      className="App"
    >
      <Header />
      <div id="configuratorWrapper" className="configuratorWrapper">
        <Configurator {...parseQueryToConfigs()} />
      </div>
    </div>
  )
}

export default App
