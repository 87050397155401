import getTemplateElitehts from './email-template-elitehts'
import getTemplateCustomer from './email-template-customer'
import Email from '../vendors/smtp-tapmod'

export const sendEmail = (data, cb) => {
  const SmtpClientID = 'elitehts'

  Email.send(
    {
      ClientID: SmtpClientID,
      To: ['sales@elitehts.com'],
      Subject: 'Elite HTS Quotation Request',
      Html: getTemplateElitehts(data),
    },
    (message) => {
      if (message === 'OK') return cb(message, 'success')
      return cb(message, 'error')
    }
  )

  Email.send(
    {
      ClientID: SmtpClientID,
      To: [data.email],
      Subject: 'Elite HTS Quotation Request',
      Html: getTemplateCustomer(data),
    },
    () => {}
  )
}
